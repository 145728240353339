<template>
  <!-- Content -->
  <div v-if="items" class="d-flex flex-column align-items-center">
    <h1 class="mt-1 mb-3">
      <span class="text-primary">
        {{ isEvent ? $t("onboarding.subscriptions.tickets") : $t("onboarding.subscriptions.plans") }}
      </span>
    </h1>
    <b-row>
      <!-- ** ORDER ** -->
      <b-col
        v-for="item in items"
        :key="item.key"
        class="flex-column d-flex align-items-center"
      >
        <plans-card 
          :item="item" 
          :discount="discounts[item.key]"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import Placeholder from "@/assets/images/placeholders/light/placeholder-dark.jpeg";
import PlansCard from "@/@core/components/plans/PlansCard.vue";
import { discounts } from "@/store/collective/plans-helpers";

export default {
  name: "OnboardingPlans",
  components: {
    PlansCard,
  },
  data() {
    return {
      isLoading: true,
      intervalMonth: true,
    };
  },
  computed: {
    items() {
      if (this.$store.getters.subscriptionPlans != null) {
        return this.$store.getters.subscriptionPlans.unpaginated;
      }
      return [];
    },
    discounts() {
      return discounts(this.items);
    },
    Placeholder() {
      return Placeholder;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    community() {
      return this.$store.getters.currentCollective;
    },
    loggedMember() {
      return this.$store.getters.loggedMember;
    },
    communitySlug() {
      return this.$route.params.communityId;
    },
    isEvent() {
      return this.community.mainType === "Event";
    }
  },
  async created() {
    this.isLoading = true;
    if (Object.values(this.community).length < 1) {
      await this.$store.dispatch("initCurrentCommunity", {
        communitySlug: this.communitySlug,
      });
    }
    await this.fetchData();
  },
  methods: {
    translate(field) {
      return translateTranslationTable(this.currentLocale, field) || "";
    },
    async fetchData() {
      this.isLoading = true;
      let modality = null;
      if (
        ["Event", "Project", "Course"].includes(this.community.mainType) &&
        this.community.modality === "hybrid"
      ) {
        modality = this.loggedMember.modality === "online" ? 1 : 2;
      }
      const response = await this.$store.dispatch("getItemsPayments", {
        itemType: "subscriptionPlans",
        page: 1,
        requestConfig: {
          communitySlug: this.communitySlug,
          orderBy: -1,
          modality,
        },
      });
      if (response.data.length === 0) {
        return this.$router.replace({
          name: "onboarding-success",
          params: { subscriptionPlanId: "thankyou" },
        });
      }

      this.isLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.price-container {
  min-height: 65.8px;
}
</style>
